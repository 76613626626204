import _get from 'lodash.get';
import { observable, flow, when } from 'mobx';

import debounce from './debounce';
import fetchSavedDocumentDetails from './fetchSavedDocumentDetails';
import pushDocument from './pushDocument';
import patchDocument from './patchDocument';
import deleteDocument from './deleteDocument';
import getDocumentDefinitionByPath from './getDocumentDefinitionByPath';
import getDocumentDefinitionByType from './getDocumentDefinitionByType';


/**
 *
 * @param {Object} store
 * @param {Object} documentStub
 * @param {string} documentStub.id
 * @param {string} documentStub.path
 * @param {Object} documentStub.metadata initial information about document during fetch
 * @param {Object} documentStub.document actual full document that has to be downloaded
 * @param {ObservableObject} sessionStore SessionStore
 */
function createDocumentDAO({ id, path, metadata, document = null, isPersisted = false, sessionStore }) {
    const self = observable({
        id,
        path,
        // metadata will be initialized from get-session response (sessionStore)
        // could be updated by pushDocument / startSavingDocumentChanges(enqueueUpdate)
        metadata,
        // document will be null until fetched (sessionStore)
        // or will be initialized from new document (startSavingDocumentChanges)
        document,

        // API state
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isPersisted,
        fetchError: null,
        persistenceError: null,
        deleteError: null,

        // JSON patches
        _updateQueue: [],

        sessionStore,

        // used by api calls
        get documentType() {
            return getDocumentDefinitionByPath(path).TYPE;
        },
        get requiredSchemaVersion() {
            return getDocumentDefinitionByType(self.documentType).SCHEMA_VERSION;
        },
        get schemaVersion() {
            return self.document?.schemaVersion // if document was fetched, use document.schemaVersion
                ?? self.metadata?.schemaVersion // if not, use metadata (document stub from profile)
                ?? null;
        },
        get hasUnsavedChanges() {
            return self.isSaving || self._updateQueue.length > 0;
        },
        get hasPersistenceError() {
            return self.persistenceError != null;
        },
        get hasFetchError() {
            return self.fetchError != null;
        },
        get hasDeleteError() {
            return self.deleteError != null;
        },

        fetchDocument: flow(function* (modelFactory) {
            self.sessionStore.setUniversalErrorMessage(null);

            // We already downloaded the document so just return it
            if (self.document) {
                return self;
            }

            self.isFetching = true;
            self.fetchError = null;

            try {
                const { documents } = yield fetchSavedDocumentDetails([ {
                    id,
                    documentType: self.documentType,
                    schemaVersion: self.requiredSchemaVersion ?? self.schemaVersion,
                } ]);
                const snapshot = _get(documents, `${path}.${id}`);

                self.document = modelFactory ? modelFactory(snapshot) : snapshot;
                self.isPersisted = true; // came from server

                return self;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error fetching document (${id}): ${error.message}`);

                self.isFetching = false;

                self.fetchError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            }
        }),
        pushDocument: flow(function* (snapshot) {
            self.sessionStore.setUniversalErrorMessage(null);

            self.isSaving = true;
            self.persistenceError = null;

            try {
                // BE will send the updated profile ONLY IF profile-propagation has happened
                // the returned profile doesn't have "documents" node inside of it
                const { profile, documentMetadata } = yield pushDocument(snapshot, self.documentType);

                if (documentMetadata != null) {
                    self.metadata = documentMetadata;
                }

                // NOTE: BE will send the updated profile ONLY IF profile-propagation has happened
                // the returned profile doesn't have "documents" node inside of it
                sessionStore._updateProfile(profile);

                self.isPersisted = true;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error pushing document (${id}): ${error.message}`);

                self.persistenceError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            } finally {
                self.isSaving = false;
            }
        }),
        _patchDocument: flow(function* (documentPatches) {
            self.sessionStore.setUniversalErrorMessage(null);

            self.isSaving = true;
            self.persistenceError = null;
            
            try {
                const { profile, documentMetadata } = yield patchDocument({
                    documentID: self.id,
                    documentType: self.documentType,
                    documentPatches,
                    documentSchemaVersion: self.schemaVersion,
                });

                if (documentMetadata != null) {
                    self.metadata = documentMetadata;
                }

                self.sessionStore._updateProfile(profile);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error patching document (${id}): ${error.message}`);
                self.persistenceError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            } finally {
                self.isSaving = false;
            }
        }),
        enqueueUpdate: function(patch) {
            self._updateQueue.push(patch);
            // Start a flush of our accumulated patches if they don't add any more for a short time.
            // Defined underneath the self closure.
            debouncedFlushChanges(false);
        },
        _flushChanges: flow(function* (shouldThrowOnFail = true) {
            // SPECIAL CASE: if a save operation is currently running, wait until it's done.
            if (self.isSaving) {
                yield when(() => self.isSaving === false);
            }

            // SPECIAL CASE: if there was SAVING done, it might have flushed
            if (self._updateQueue.length === 0) {
                return;
            }

            self.isSaving = true;
            self.persistenceError = null;
            self.sessionStore.setUniversalErrorMessage(null);

            if (!self.isPersisted) {
                // First time we persist needs to be a POST request
                self._updateQueue.splice(0); // before we push, clear the updates from the queue
                
                try {
                    // If the document is MST, we need to convert it to a plain object.
                    // https://mobx-state-tree.js.org/tips/more-tips#tojson-for-debugging is a replacement for `getSnapshot` that doesn't require us to import MST.
                    const snapshot = self.document.toJSON?.() ?? self.document;

                    yield self.pushDocument(snapshot);
                } catch (error) {
                    if (shouldThrowOnFail) {
                        throw error;
                    }
                }
            } else {
                // Any updates to a persisted document must be PATCH requests.
                const batchedPatches = self._updateQueue.splice(0);

                try {
                    yield self._patchDocument(batchedPatches);
                } catch (error) {
                    // If we failed, we must preserve the ORIGINAL order of the patches.
                    self._updateQueue.unshift(...batchedPatches);

                    if (shouldThrowOnFail) {
                        throw error;
                    }
                }
            }

            self.isSaving = false;
        }),
        // NOTE: Auto and Home applications can be deleted in dashboard
        // if you are going to use this function please make sure it's safe to use
        deleteDocument: flow(function* (){
            self.deleteError = null;
            self.sessionStore.setUniversalErrorMessage(null);
            self.isDeleting = true;

            try {
                yield deleteDocument(self.documentType, id);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`[DAO] Error deleting document (${id}): ${error.message}`);

                self.isDeleting = false;

                self.deleteError = error;
                self.sessionStore.setUniversalErrorMessage(error);

                throw error;
            }

            self.isDeleting = false;
        }),
    });

    // This needs to be declared down here so we have reference to self.
    const debouncedFlushChanges = debounce(self._flushChanges);

    // Need to return variable or context is lost if destructured
    return self;
}

export default createDocumentDAO;
