import { getAllEvents } from './noticeErrorEvent';
import { Experiments } from './getExperimentSegment';


/**
 * Eeek! Something bad happened, the sky is falling. Tell everyone: console.error() and newrelic.noticeError()
 * @param {Object} error some error object
 * @param {Object} [moreInfo] key/value object with additional context
 * @returns undefined
 *
 * @example noticeError(new RangeError(`[someFunctionName] -- unexpected value for thingType: “${thingType}”`),
 *      {
 *          documentId: context.activeDocument.id,
 *          email: context.activeDocument.occupant?.email,
 *          exception: ex,
 *      });
 */

function noticeError(error, moreInfo) {
    let extendedInfo = moreInfo;
    const { saveEvents, pendingEvents } = getAllEvents();   

    if (typeof newrelic !== 'undefined') {
        // Augment with any relevant details before this happened
        extendedInfo = {
            ...extendedInfo,
            noticeErrorSaveEvents: saveEvents,
            noticeErrorPendingEvents: pendingEvents,
            experiments: Experiments.toJSON(),
        };

        // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
        newrelic?.noticeError(error, extendedInfo);
    }

    // eslint-disable-next-line no-console
    console.error(error, extendedInfo);   // the error object will be .toString()’d
}

export default noticeError;
