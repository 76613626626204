import lazyWithRetry from '../functions/lazyWithRetry';
import withSuspenseIconFallback from './withSuspenseIconFallback';


const IconArrowLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconArrow'))
);
const IconAwardLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconAward'))
);
const IconBarChartLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconBarChart'))
);
const IconBellLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconBell'))
);
const IconBookLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconBook'))
);
const IconBriefcaseLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconBriefcase'))
);
const IconCalculatorLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCalculator'))
);
const IconCalendarLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCalendar'))
);
const IconCakeLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCake'))
);
const IconCarLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCar'))
);
const IconCartLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCart'))
);
const IconCheckmarkLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCheckmark'))
);
const IconClockLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconClock'))
);
const IconConversationLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconConversation'))
);
const IconCreditCardLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconCreditCard'))
);
const IconDiamondLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconDiamond'))
);
const IconDollarLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconDollar'))
);
const IconExclamationMarkLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconExclamationMark'))
);
const IconEyeLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconEye'))
);
const IconFiltersLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconFilters'))
);
const IconGasTankLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconGasTank'))
);
const IconGhostLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconGhost'))
);
const IconGiftLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconGift'))
);
const IconHeadsetLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconHeadset'))
);
const IconHeartLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconHeart'))
);
const IconHourglassLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconHourglass'))
);
const IconHouseLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconHouse'))
);
const IconKeyLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconKey'))
);
const IconLifePreserverLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconLifePreserver'))
);
const IconLightBulbLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconLightBulb'))
);
const IconLightningLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconLightning'))
);
const IconLockLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconLock'))
);
const IconMailOpenLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconMailOpen'))
);
const IconMapleLeafLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconMapleLeaf'))
);
const IconMapPinLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconMapPin'))
);
const IconMicrophoneLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconMicrophone'))
);
const IconMinusLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconMinus'))
);
const IconNoDollarLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconNoDollar'))
);
const IconNoteLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconNote'))
);
const IconPaperLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPaper'))
);
const IconPaperStackLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPaperStack'))
);
const IconPeopleLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPeople'))
);
const IconPersonLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPerson'))
);
const IconPhoneLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPhone'))
);
const IconPiggyLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPiggy'))
);
const IconPlaneLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPlane'))
);
const IconPlusLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPlus'))
);
const IconPodcastMicrophoneLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconPodcastMicrophone'))
);
const IconQuestionMarkLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconQuestionMark'))
);
const IconRatehubLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconRatehub'))
);
const IconRefreshLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconRefresh'))
);
const IconRobotLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconRobot'))
);
const IconSearchLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconSearch'))
);
const IconShareLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconShare'))
);
const IconShieldLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconShield'))
);
const IconSmileLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconSmile'))
);
const IconSpeechBubbleLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconSpeechBubble'))
);
const IconSpeedometerLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconSpeedometer'))
);
const IconStarLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconStar'))
);
const IconStudentLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconStudent'))
);
const IconSuitcaseLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconSuitcase'))
);
const IconThumbsDownLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconThumbsDown'))
);
const IconThumbsUpLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconThumbsUp'))
);
const IconTrendingLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconTrending'))
);
const IconTrophyLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconTrophy'))
);
const IconUmbrellaLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconUmbrella'))
);
const IconUserLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconUser'))
);
const IconWalletLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconWallet'))
);
const IconWrenchLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconWrench'))
);
const IconXLazy = withSuspenseIconFallback(
    lazyWithRetry(() => import('./icons/IconX'))
);

export {
    IconArrowLazy,
    IconAwardLazy,
    IconBarChartLazy,
    IconBellLazy,
    IconBookLazy,
    IconBriefcaseLazy,
    IconCalculatorLazy,
    IconCalendarLazy,
    IconCakeLazy,
    IconCarLazy,
    IconCartLazy,
    IconCheckmarkLazy,
    IconClockLazy,
    IconConversationLazy,
    IconCreditCardLazy,
    IconDiamondLazy,
    IconDollarLazy,
    IconExclamationMarkLazy,
    IconEyeLazy,
    IconFiltersLazy,
    IconGasTankLazy,
    IconGhostLazy,
    IconGiftLazy,
    IconHeadsetLazy,
    IconHeartLazy,
    IconHourglassLazy,
    IconHouseLazy,
    IconKeyLazy,
    IconLifePreserverLazy,
    IconLightBulbLazy,
    IconLightningLazy,
    IconLockLazy,
    IconMailOpenLazy,
    IconMapleLeafLazy,
    IconMapPinLazy,
    IconMicrophoneLazy,
    IconMinusLazy,
    IconNoDollarLazy,
    IconNoteLazy,
    IconPaperLazy,
    IconPaperStackLazy,
    IconPeopleLazy,
    IconPersonLazy,
    IconPhoneLazy,
    IconPiggyLazy,
    IconPlaneLazy,
    IconPlusLazy,
    IconPodcastMicrophoneLazy,
    IconQuestionMarkLazy,
    IconRatehubLazy,
    IconRefreshLazy,
    IconRobotLazy,
    IconSearchLazy,
    IconShareLazy,
    IconShieldLazy,
    IconSmileLazy,
    IconSpeechBubbleLazy,
    IconSpeedometerLazy,
    IconStarLazy,
    IconStudentLazy,
    IconSuitcaseLazy,
    IconThumbsDownLazy,
    IconThumbsUpLazy,
    IconTrendingLazy,
    IconTrophyLazy,
    IconUmbrellaLazy,
    IconUserLazy,
    IconWalletLazy,
    IconWrenchLazy,
    IconXLazy,
};
