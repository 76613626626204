import { v4 as uuid } from 'uuid';
import Config from '../definitions/Config';


const saveEvents = [];
const pendingEvents = [];
const MAXIMUM_ENTRY_COUNT = 20;


/**
 * Record relevant tracing information. Will be automatically included in any noticeError calls.
 * @param {string} message - message to be logged
 * @param {...any} additionalValues - Additional values to log with the error message.
 */
function noticeErrorSaveEvent(message, ...additionalValues) {
    // Prefer a simplified message.
    saveEvents.push({ 
        message, 
        ...(additionalValues.length > 0 && { additionalValues }), 
    });

    // Limit to a certain amount of only the most recent entries.
    if (saveEvents.length > MAXIMUM_ENTRY_COUNT) {
        saveEvents.shift();
    }

    // Log to the console if configured to do so.
    if (Config.ENABLE_NOTICE_ERROR_SAVE_EVENT_CONSOLE_INFO) {
        // eslint-disable-next-line no-console
        console.info(message, additionalValues);
    }
}

/**
 * Record relevant pending event information. Will be automatically included in any noticeError calls.
 * @param {string} message - message to be logged
 * @param {...any} additionalValues - Additional values to log with the error message.
 * @returns {string} The unique identifier for the logged error event.
 */
function noticeErrorPendingEvent(message, ...additionalValues) {
    const id = uuid();
    // Prefer a simplified message.
    pendingEvents.push({ 
        id, 
        message, 
        ...(additionalValues.length > 0 && { additionalValues }), 
    });

    // Limit to a certain amount of only the most recent entries.
    if (pendingEvents.length > MAXIMUM_ENTRY_COUNT) {
        pendingEvents.shift();
    }

    // Log to the console if configured to do so.
    if (Config.ENABLE_NOTICE_ERROR_PENDING_EVENT_CONSOLE_INFO) {
        // eslint-disable-next-line no-console
        console.info(message, additionalValues);
    }

    return id;
}

/**
 * Removes a pending error event from the pendingEvents array by its ID.
 * @param {string} id - The ID of the error event to be removed.
 */
function removePendingErrorEvent(id) {
    const index = pendingEvents.findIndex(event => event.id === id);
    if (index !== -1) {
        pendingEvents.splice(index, 1);
    }
}

/** Get the most recent noticeErrorSaveEvent calls which have been made. */
function getAllEvents() {
    return { 
        saveEvents,
        pendingEvents,
    };
}


export { 
    noticeErrorSaveEvent, 
    noticeErrorPendingEvent, 
    removePendingErrorEvent, 
    getAllEvents,  
};
