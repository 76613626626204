import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/delete-document`;

/**
 * @param {string} documentType
 * @param {string} documentID
 * @returns
 */
async function deleteDocument(documentType, documentID) {
    await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                documentType,
                documentID,
            }),
        },
    }, 'deleteDocument');
}

export default deleteDocument;
export {
    API_ENDPOINT,
};
