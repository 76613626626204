import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
 * 
 * @param {object} params
 * @param {string} params.documentId
 * @param {string} params.documentType
 * @param {string} params.eventType
 * @param {object} params.content
 *  
 * @returns {Promise<void>}
 */
async function pushDocumentEvent({
    documentId,
    documentType,
    eventType,
    content,
}) {
    //! NOTE: Accepts and returns an `eventId` in the response, but this should not be used by the client
    await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                documentId,
                documentType,
                eventType,
                content,
            }),
        },
    }, 'pushDocumentEvent');
}

export const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/document-events`;

export default pushDocumentEvent;