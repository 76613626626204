import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/update`;


/**
 * @public
 * Send an update to the details of the currently logged-in profile.
 * @param {Object} values
 */
async function pushProfileUpdate(values) {
    // Create one JSON patch per attribute being changed.
    const patches = Object.entries(values).map(([ key, value ]) => ({
        'op': 'replace',
        'path': `/${key}`,
        'value': value,
    }));

    await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(patches),
        },
        shouldLogError(error) {
            return ![ 401, 403 ].includes(error.status);
        },
    }, 'pushProfileUpdate');

    // TODO: validate vv
    // NOTE: there is **NO** body in the response on a valid submission.
    // It was noted there should likely be added something in the future.
    // return await response.json()?.data
}

export default pushProfileUpdate;
export { API_ENDPOINT };
