import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/logout`;

/**
 *
 * @returns {Promise<*>}
 */
async function deauthenticateUser() {
    await fetchJSON({
        url: API_ENDPOINT,
        options: {
            credentials: 'include',
        },
    }, 'deauthenticateUser');
}

export default deauthenticateUser;
export {
    API_ENDPOINT,
};
