import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/verify`;

/**
 *
 * @param {string} email
 * @param {string} verificationToken
 * @param {Object=} queryParams
 * @param {string=} queryParams.lang
 * @param {Object=} documentOptions
 * @param {string=} documentOptions.documentToken
 * @param {string=} documentOptions.documentRedirect
 * @param {string=} variant
 * @returns {Promise<*>}
 */
async function verifyAccount({
    email,
    validationToken,
    queryParams,
    documentOptions,
    variant,
}) {
    if (!email) {
        throw new Error(`Email is required to verify new account. received: ${email}`);
    }
    if (!validationToken) {
        throw new Error(`Verification token is required to verify new account. received: ${validationToken}`);
    }

    const { documentToken, documentRedirect } = documentOptions ?? {};
    const { lang, ...otherQueryParams } = queryParams ?? {};

    const jsonResponse = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                verificationToken: validationToken, // note: naming is not consistent
                queryParams: otherQueryParams,
                documentToken,
                documentRedirect,
                variant,
                lang,
            }),
            credentials: 'include',
        },
        shouldLogError(error) {
            return ![ 401, 403 ].includes(error.status);
        },
    }, 'verifyAccount');

    return jsonResponse.data;
}

export {
    verifyAccount,
    API_ENDPOINT,
};
