/**
 * Checks if the given error is a network error.
 *
 * A network error is identified by being an instance of TypeError
 * and having a message that matches one of the known network error messages.
 *
 * @param {Error} error - The error to check.
 * @returns {boolean} True if the error is a network error, false otherwise.
 */
function isNetworkError(error) {
    const UNDICI_RETRYABLE_ERRORS = [
        'SocketError',
    ];

    return UNDICI_RETRYABLE_ERRORS.includes(error.name)
        || (error instanceof TypeError
        && [ 'Failed to fetch', 'failed fetch', 'fetch failed' ].includes(error.message));
}

export default isNetworkError;
