/**
 * Checks whether we inside a widget, based on being invoked in an <iframe> element.
 * 
 * @returns {boolean}
 */
export default function isWidget() {
    if (typeof window === 'undefined') {
        return false;
    } else {
        return window.self !== window.top;
    }
}
