import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const PATCH_DOCUMENT_URL = `${Config.PROFILE_BFF_URL}/save-document`;

/**
 * Push an array of JSON Patches (http://jsonpatch.com/) to update a document.
 *
 * Patches will need to be relative to the document (ie. document path is /).
 * The FaaS will use the document type to resolve the patches correctly.
 *
 * @typedef {{
 *    op: 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test'
 *    path: string
 *    value: any
 * }} JsonPatch
 *
 * @param {object} document
 * @param {string} document.documentID
 * @param {string} document.documentType
 * @param {Array<JsonPatch>} document.documentPatches
 * @param {object} documentSchemaVersion number
 * @returns {Promise<{ profile?: any, documentMetadata: any }>} profile without documents node inside
 */
async function patchDocument({
    documentID,
    documentType,
    documentPatches,
    documentSchemaVersion,
}) {
    if (!documentPatches.length) {
        return;
    }

    return (await fetchJSON({
        url: PATCH_DOCUMENT_URL,
        options: {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                documentID,
                documentType,
                documentPatches,
                documentSchemaVersion,
            }),
        },
        shouldLogError(error) {
            return ![ 401, 403 ].includes(error.status);
        },
    }, 'patchDocument')).data ?? {};
}

export default patchDocument;
export {
    PATCH_DOCUMENT_URL,
};
